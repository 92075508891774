<style lang="scss" scoped>
.custom_box {
	flex: 1;
	display: flex;
	justify-content: flex-end;
}
.line {
	margin: 0.3rem;
	height: 1px;
	background-color: $line_color;
}
</style>

<template>
	<div>
		<!-- <top-nav @back="back" :bgColor="$store.state.cps_brindInfo.brandColor">“耀守护”教培机构责任险-{{planName}}</top-nav> -->
		<top-nav @back="back" :bgColor="$store.state.cps_brindInfo.brandColor">“耀守护”培训机构责任险-{{ planName }}</top-nav>

		<!-- 表单 -->
		<van-form ref="form" @submit="submit">
			<active-title>场地信息</active-title>
			<van-field label="经营项目" readonly clickable name="project" :value="form.project" placeholder="请选择经营项目" @click="projectShow = true" :rules="rules.project"></van-field>
			<van-field label="参与人数" readonly clickable name="peopleNum" :value="form.peopleNum" placeholder="请选择参与人数" @click="peopleNumShow = true" :rules="rules.peopleNum"></van-field>
			<van-field label="场地面积" readonly clickable name="squre" :value="form.squre" placeholder="请选择场地面积" @click="squreShow = true" :rules="rules.squre"></van-field>
			<van-field label="附加猝死" name="switch">
				<template #input>
					<van-switch v-model="form.hasDie" size="20" />
				</template>
			</van-field>
			<van-field :value="form.site" :rules="rules.site" name="site" is-link readonly label="省市区" placeholder="请选择省市区" @click="openCascader" />
			<van-popup v-model="newshow" round position="bottom">
				<van-cascader :field-names="fieldNames" v-model="cascaderValue" title="请选择省市区" :options="cascaderoptions" @close="newshow = false" @change="onChange" @finish="onFinish" />
			</van-popup>

			<active-title>投保信息</active-title>
			<van-field label="保障期限" readonly value="1年"></van-field>
			<van-field label="起保日期" readonly clickable name="startDate" :value="form.startDate" placeholder="请选择起保日期" @click="dateShow = true" :rules="rules.startDate"></van-field>
			<van-field label="终保日期" readonly name="endDate" :value="form.endDate" placeholder="根据保障期限和起保日期决定"></van-field>

			<active-title>投保人信息</active-title>
			<van-field label="企业名称" name="insCompanyName" v-model="form.insCompanyName" placeholder="请填写企业名称" :rules="rules.insCompanyName"></van-field>
			<van-field label="统一社会信用代码" name="insCardNo" v-model="form.insCardNo" placeholder="请填写统一社会信用代码" :rules="rules.insCardNo"></van-field>
			<van-field label="营业地址" name="insAddress" v-model="form.insAddress" placeholder="请填写营业执照地址" :rules="rules.insAddress"></van-field>
			<van-field label="联系人" name="insLinkName" v-model="form.insLinkName" placeholder="请填写联系人姓名" :rules="rules.insLinkName"></van-field>
			<van-field label="手机号码" name="insMobile" v-model="form.insMobile" placeholder="请填写联系人的手机号码" :rules="rules.insMobile"></van-field>
			<van-field label="电子邮箱" name="insEmail" v-model="form.insEmail" placeholder="请填写您的电子邮箱" :rules="rules.insEmail"></van-field>
			<van-field label="发票抬头" name="insCompanyName" v-model="form.insCompanyName" placeholder="默认投保人，不可更改" readonly></van-field>

			<active-title>
				<span>被保人信息</span>
				<template #cont>
					<div class="custom_box">
						<van-button type="info" size="small" native-type="button" @click="sameInsure">同投保人</van-button>
					</div>
				</template>
			</active-title>
			<van-field label="被保企业名称" name="assuredCompanyName" v-model="form.assuredCompanyName" placeholder="请填写被保企业名称" :rules="rules.assuredCompanyName"></van-field>
			<van-field label="统一社会信用代码" name="assuredCardNo" v-model="form.assuredCardNo" placeholder="请填写被保人统一社会信用代码" :rules="rules.assuredCardNo"></van-field>
			<van-field label="营业地址" name="assuredAddress" v-model="form.assuredAddress" placeholder="请填写被保人实际保障地址" :rules="rules.assuredAddress"></van-field>
		</van-form>

		<div class="line"></div>

		<!-- 条款信息 -->
		<product-clause-info ref="clauseInfo" :proInfo="product" :information="information" pageName="cpsSafeguardClause" />

		<price-buy-btn :price="money" @buy="buy"></price-buy-btn>

		<m-picker v-model="projectShow" :columns="projectList" title="经营项目" @confirm="projectCheck" @cancel="projectShow = false"></m-picker>
		<p-picker v-model="peopleNumShow" title="参与人数" :columns="peopleNumList" @confirm="peopleNumCheck"></p-picker>
		<p-picker v-model="squreShow" title="参与人数" :columns="squreList" @confirm="squreCheck"></p-picker>
		<van-calendar v-model="dateShow" @confirm="startDateCheck" :min-date="minDate" color="#2594EF" :formatter="$base.calendarFormatter" />
	</div>
</template>

<script>
import { Form, Field, Cell, Picker, Switch, Calendar, Button, Toast, Cascader, Popup } from 'vant';
import mPicker from '@/components/mPicker.vue';
import productClauseInfo from '@/components/productClauseInfo.vue';
import { http_getPalns } from '@/request/sundry.js';
import { http_getDict } from '@/request/common.js';
import { productDetail, getBillsInfo } from '@/request/api.js';
import { http_jpjg_createOrder } from '@/request/liability';
import regular from '@/assets/js/regular.js';
import Mtils from 'mtils';
import moment from 'moment';
import addressList from '@/assets/tool/jpAddress.json';
export default {
	name: 'cps_jiaoPeiJiGou_insure', // 投保页面
	components: {
		mPicker,
		productClauseInfo,
		[Form.name]: Form,
		[Field.name]: Field,
		[Cell.name]: Cell,
		[Picker.name]: Picker,
		[Switch.name]: Switch,
		[Calendar.name]: Calendar,
		[Button.name]: Button,
		[Cascader.name]: Cascader,
		[Popup.name]: Popup,
	},
	data() {
		return {
			newshow: false,
			fieldValue: '',
			cascaderValue: '',
			cascaderoptions: [],
			fieldNames: {
				text: 'label',
				value: 'value',
				children: 'children',
			},

			uid: '',
			p1: '',
			p2: '',
			planId: '',
			planName: '',
			planId2: '', // 对应的第二部分的计划id

			product: {},
			information: {},

			priceList: [], // 公众险所有费率
			priceList2: [], // 雇主险所有费率

			price1: null, // 公众险所选费率信息
			price2: null, // 雇主险所选费率信息

			// 下拉框选择项
			projectDetailData: [], // 所有项目
			peopleNumList: ['50人以下', '50-200人', '200人以上'], // 参与人数
			squreList: ['500平方米以下', '500-1000平方米', '1000平方米以上'], // 场地面积

			projectShow: false,
			peopleNumShow: false,
			squreShow: false,
			dateShow: false,

			project: '', // 所选项目的类别

			form: {
				project: '', // 项目
				peopleNum: '',
				squre: '',
				hasDie: false,
				startDate: '',
				endDate: '',
				siteList: [],
				site: '',
				// 投保人信息
				insCompanyName: '',
				insCardNo: '',
				insAddress: '',
				insLinkName: '',
				insMobile: '',
				insEmail: '',

				// 被保人信息
				assuredCompanyName: '',
				assuredCardNo: '',
				assuredAddress: '',
			},

			rules: {
				project: [{ required: true, trigger: 'onChange' }],
				peopleNum: [{ required: true, trigger: 'onChange' }],
				squre: [{ required: true, trigger: 'onChange' }],
				startDate: [{ required: true, trigger: 'onChange' }],

				insCompanyName: [{ required: true, trigger: 'onChange' }],
				insCardNo: [
					{ required: true, trigger: 'onChange' },
					{ validator: this.creditCode, message: '证件号码有误' },
				],
				insAddress: [{ required: true, trigger: 'onChange' }],
				insLinkName: [{ required: true, trigger: 'onChange' }],
				insMobile: [
					{ required: true, trigger: 'onChange' },
					{ pattern: regular.phone, message: '手机号码有误' },
				],
				insEmail: [{ validator: this.emailCheck, message: '邮箱地址有误', trigger: 'onChange' }],

				assuredCompanyName: [{ required: true, trigger: 'onChange' }],
				assuredCardNo: [
					{ required: true, trigger: 'onChange' },
					{ validator: this.creditCode, message: '证件号码有误' },
				],
				assuredAddress: [{ required: true, trigger: 'onChange' }],
				site: [{ required: true, trigger: 'onChange' }],
			},
		};
	},
	computed: {
		projectList() {
			let group = [];
			this.priceList.forEach(item => {
				if (!group.includes(item.eleView1)) {
					group.push(item.eleView1);
				}
			});

			let result = group.map(groupName => {
				return {
					label: groupName,
					options: this.projectDetailData[`${this.p1}#${groupName}`],
				};
			});

			return result;
		},

		hasDie() {
			if (this.form.hasDie) {
				return '含猝死';
			}
			return '不含猝死';
		},

		// 保费
		money() {
			let m1 = 0,
				m2 = 0;
			let m1_priceList = this.priceList.filter(item => item.comboId == this.planId && item.eleView1 === this.project && item.eleUse1 === this.hasDie);
			let m2_priceList = this.priceList2.filter(item => item.comboId == this.planId2 && item.eleView1 === this.project);
			if (m1_priceList.length) {
				m1 = Number(m1_priceList[0].price);
				this.price1 = m1_priceList[0];
			}

			if (m2_priceList.length) {
				m2 = Number(m2_priceList[0].price);
				this.price2 = m2_priceList[0];
			}

			return (m1 + m2).toFixed('2');
		},

		minDate() {
			return new Date(Date.now() + 24 * 3600 * 1000);
		},
	},
	created() {
		this.cascaderoptions = addressList;
		this.init();
	},
	beforeRouteLeave(to, from, next) {
		if (to.name == 'cps_jiaoPeiJiGou_index') {
			const store = from.matched[0].instances.default.$store;
			store.commit('set_cps_keepLiveCom', 'cpsHome,cpsProduct');
			khs.stop();
		}
		if (to.name === 'cpsSafeguardClause') {
			const store = from.matched[0].instances.default.$store;
			store.commit('set_cps_keepLiveCom', 'cpsHome,cpsProduct,cps_jiaoPeiJiGou_insure');
		}
		next();
	},
	beforeRouteEnter(to, from, next) {
		if (from.name === 'cps_jiaoPeiJiGou_index') {
			const store = from.matched[0].instances.default.$store;
			store.commit('set_cps_keepLiveCom', 'cpsHome,cpsProduct,cps_jiaoPeiJiGou_insure');
		}
		next(vm => {
			if (from.name == null) {
				vm.refresh();
			}
		});
	},
	methods: {
		// // 刷新是跳转路由
		refresh() {
			// console.log('刷新是跳转路由','this.$store.state.cps_createOrderRouteName');
			if (this.$store.state.cps_createOrderRouteName == '') {
				this.$router.push({ name: 'cpsProduct', query: { code: this.$store.state.cps_code.code } });
			}
		},
		// 打开省市区
		openCascader() {
			this.newshow = true;
		},

		onChange(option) {},
		// 完成省市区
		onFinish({ selectedOptions }) {
			this.form.site = selectedOptions.map(option => option.label).join('/');
			this.form.siteList = selectedOptions.map(item => item.value);
			this.newshow = false;
		},
		init() {
			if (this.queryCheck()) {
				let query = this.$route.query;
				this.p1 = query.p1;
				this.p2 = query.p2;
				this.planId = query.planId;
				this.planName = query.planName;
				this.uid = query.uid;
				if (this.$route.params && this.$route.params.detailTransNo) {
					this.detailTransNo = this.$route.params.detailTransNo;
				}
				this.getProduct();
				this.getProjectList();
				this.getProductPrice();
			}
		},

		// 参数检查
		queryCheck() {
			let query = this.$route.query;
			return Boolean(query.p1 && query.p2 && query.planId && query.uid);
			// return Boolean(query.p1 && query.p2 && query.planId)
		},

		// 返回
		back() {
			this.$router.go(-1);
		},

		// 获取商品详情
		getProduct() {
			productDetail(this.p2).then(res => {
				let planNameIdDict = {};
				res.combos.forEach(item => {
					planNameIdDict[item.comboName] = item.comboId;
				});
				this.planId2 = planNameIdDict[this.planName];

				productDetail(this.p1).then(res => {
					this.product = res.product;
					this.information = res.information;
				});
			});
		},

		// 获取产品费率
		getProductPrice() {
			// 公众险费率
			http_getPalns(this.p1).then(res => {
				res.forEach(item => {
					this.priceList.push(...item.productPriceList);
				});
			});

			// 雇主险费率
			http_getPalns(this.p2).then(res => {
				res.forEach(item => {
					this.priceList2.push(...item.productPriceList);
				});
			});
		},

		// 获取详情列表数据字典
		getProjectList() {
			http_getDict('liabilityProjectDetail').then(res => {
				res.forEach(item => {
					this.projectDetailData[item.code] = String(item.value).replace(/,|，/g, ',').split(',');
				});
			});
		},

		// 选择项目
		projectCheck(list) {
			this.setProjectType(list);
			this.form.project = list.join(',');
			this.projectShow = false;
		},

		// 设置项目类型（是否为高风险）
		setProjectType(list) {
			let hPlanList = this.projectDetailData[`${this.p1}#高风险运动版`];

			if (!list.length) {
				this.project = '';
				return;
			}
			// 高风险运动版的级别高
			if (list.some(item => hPlanList.includes(item))) {
				this.project = '高风险运动版';
				return;
			}

			this.project = '常规运动版';
		},

		// 选择参与人数
		peopleNumCheck(v) {
			this.form.peopleNum = v;
		},

		// 选择场地面积
		squreCheck(v) {
			this.form.squre = v;
		},

		// 选择起保日期
		startDateCheck(date) {
			this.form.startDate = this.$base.dateFormater(date);
			this.form.endDate = moment(date).add(1, 'y').subtract(1, 'd').format('YYYY/MM/DD');

			// 做活动，保障期限改为13个月
			// this.form.endDate = moment(date).add(13, 'M').subtract(1, 'd').format('YYYY/MM/DD')
			this.dateShow = false;
		},

		// 社会统一信用代码校验
		creditCode(val) {
			return Mtils.validation.isCreditCode(val);
		},

		// 邮箱地址校验
		emailCheck(val) {
			if (!String(val).replace(/ /g, '')) {
				return true;
			}
			return regular.email.test(val);
		},

		// 同投保人
		sameInsure() {
			this.$refs.form.validate(['insCompanyName', 'insCardNo', 'insAddress']).then(() => {
				this.form.assuredCompanyName = this.form.insCompanyName;
				this.form.assuredCardNo = this.form.insCardNo;
				this.form.assuredAddress = this.form.insAddress;
			});
		},

		buy() {
			this.$refs.form.submit();
		},

		submit(data) {
			let hasCheckNarrate = this.$refs.clauseInfo.getResult();
			if (!hasCheckNarrate) {
				return;
			}
			this.submitOrder();
		},

		// 获取两个订单关联的唯一字符串
		getRelationStr() {
			return `$PCZRX-$RELATION-${this.uid}-${Date.now()}`;
		},

		// TODO:提交订单
		async submitOrder() {
			let relationString = this.getRelationStr();

			// 并发两个请求
			let send1 = {
				insBill: {
					businessNature: `教育体育培训机构（培训项目：${this.form.project}）`,
					insureType: 1,
					platform: 'CPS',
					suddenDeath: this.hasDie === '含猝死',
					proId: this.p1,
					enableDate: this.$base.submitDateFormatter(this.form.startDate, 1),
					disEnableDate: this.$base.submitDateFormatter(this.form.endDate, 2),
					assuredSendMsg: 1,
					remark: relationString,
					participants: this.form.peopleNum, //参与人数
					siteArea: this.form.squre, //产地面积
					contactName: this.form.insLinkName,
					province: this.form.siteList.length ? this.form.siteList[0] : '',
					city: this.form.siteList.length ? this.form.siteList[1] : '',
					area: this.form.siteList.length ? this.form.siteList[2] : '',
				},
				insurer: {
					businessAddress: this.form.insAddress,
					insAddress: this.form.insAddress,
					insCredentials: this.form.insCardNo,
					insCredentialsType: 3,
					insEmail: this.form.insEmail,
					insName: this.form.insCompanyName,
					insPhone: this.form.insMobile,
					insType: 2,
					saveContactsFlag: 0,
					insSex: 9,
				},
				assuredList: [
					{
						address: this.form.assuredAddress,
						businessAddress: this.form.assuredAddress,
						buy: 1,
						certificateContent: this.form.assuredCardNo,
						certificateType: 3,
						name: this.form.assuredCompanyName,
						type: 2,
						sex: 9,
					},
				],
				productPriceVo: {
					comboId: this.price1.comboId,
					eleUse1: this.price1.eleUse1,
					eleView1: this.price1.eleView1,
					insureTime: this.price1.insureTime,
					insureTimeUnit: this.price1.insureTimeUnit,
					priceId: this.price1.priceId,
					proId: this.price1.proId,
					sex: this.price1.sex,
					viewAgeBelong: this.price1.viewAgeBelong,
					viewTime: this.price1.viewTime,
				},
			};

			let send2 = {
				insBill: {
					businessNature: `教育体育培训机构（培训项目：${this.form.project}）`,
					insureType: 1,
					platform: 'CPS',
					suddenDeath: this.hasDie === '含猝死',
					proId: this.p2,
					enableDate: this.$base.submitDateFormatter(this.form.startDate, 1),
					disEnableDate: this.$base.submitDateFormatter(this.form.endDate, 2),
					assuredSendMsg: 1,
					remark: relationString,
					participants: this.form.peopleNum, //参与人数
					siteArea: this.form.squre, //产地面积
					contactName: this.form.insLinkName,
					province: this.form.siteList.length ? this.form.siteList[0] : '',
					city: this.form.siteList.length ? this.form.siteList[1] : '',
					area: this.form.siteList.length ? this.form.siteList[2] : '',
				},
				insurer: {
					businessAddress: this.form.insAddress,
					insAddress: this.form.insAddress,
					insCredentials: this.form.insCardNo,
					insCredentialsType: 3,
					insEmail: this.form.insEmail,
					insName: this.form.insCompanyName,
					insPhone: this.form.insMobile,
					insType: 2,
					saveContactsFlag: 0,
					insSex: 9,
				},
				assuredList: [
					{
						address: this.form.assuredAddress,
						businessAddress: this.form.assuredAddress,
						buy: 1,
						certificateContent: this.form.assuredCardNo,
						certificateType: 3,
						name: this.form.assuredCompanyName,
						type: 2,
						sex: 9,
					},
				],
				productPriceVo: {
					comboId: this.price2.comboId,
					eleUse1: this.price2.eleUse1,
					eleView1: this.price2.eleView1,
					insureTime: this.price2.insureTime,
					insureTimeUnit: this.price2.insureTimeUnit,
					priceId: this.price2.priceId,
					proId: this.price2.proId,
					sex: this.price2.sex,
					viewAgeBelong: this.price2.viewAgeBelong,
					viewTime: this.price2.viewTime,
				},
			};

			const toast = Toast({
				type: 'loading',
				message: '订单提交中...',
				forbidClick: true,
				duration: 0,
			});
			Promise.all([http_jpjg_createOrder(send1), http_jpjg_createOrder(send2)]).then(resList => {
				toast.message = '核保中...';
				let ids = resList.map(item => item.data.id).join(',');

				// 该产品需要核保
				this.getBillState(ids).then(() => {
					Toast.clear();
					khs.finish(resList[0].data.id);
					this.$store.commit('set_cps_billId', ids);
					this.$router.push({
						name: 'cpsOrderPay',
						params: {
							isRedict: false,
							formatterType: 2,
						},
					});
				});
			});
		},

		// 查询订单详情，获取订单状态，订单为“待支付”状态时，返回Promise.resolve
		async getBillState(ids) {
			let res = await getBillsInfo(ids);
			let list = res.list;
			if (list.every(item => item.billInfo.billState == 2)) {
				// 待支付
				return Promise.resolve();
			} else {
				// 继续检查订单状态
				await this.sleep(1000);
				return this.getBillState(ids);
			}
		},

		sleep(time) {
			return new Promise(resolve => {
				setTimeout(() => {
					resolve();
				}, time);
			});
		},
	},
};
</script>
